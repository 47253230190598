import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import Layout from "../components/layout"
import TableOfContents from "../components/table_of_contents"
import Tags from "../components/tags"
import { graphql } from "gatsby"
import { motion } from "framer-motion"

const BlogPost = ({ data: { file } }) => {
    const { childMdx: { frontmatter: { title, date, tags = [] }, tableOfContents, body, id, excerpt } } = file
    return (<Layout pageTitle={title} description={excerpt}>
        <motion.h1 layoutId={`${id}title`}>{title}</motion.h1>
        <motion.small layoutId={`${id}date`}>{date}</motion.small>
        <Tags tags={tags || []} layoutId={`${id}tags`} />
        <hr />
        <TableOfContents tableOfContents={tableOfContents.items} />
        <hr />
        <MDXRenderer>
            {body}
        </MDXRenderer>
    </Layout>)
}

export default BlogPost

export const query = graphql`
query ($id: String) {
  file(
    sourceInstanceName: {eq: "blog"}
    extension: {in: ["md", "mdx"]}
    childMdx: {frontmatter: {draft: {ne: true}}}
    id: {eq: $id}
  ) {
    name
    childMdx {
id
      tableOfContents
      body
      frontmatter {
        date
        title
        tags
      }
excerpt
    }
  }
}
`
