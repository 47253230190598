import React from "react"
import "./layout.scss"

const ContentsElement = ({ contents, parentKey = "" }) => {
    return (<ul>
        {contents.map(({ url, title, items = [] }) => <li key={`${parentKey}${title}`}><a href={url}>{title}</a>
            <ContentsElement contents={items} parentKey={`${parentKey}${title}`} /></li>)}
    </ul>)
}

const TableOfContents = ({ tableOfContents = [] }) => {
    return <div className="tableOfContents">
        <h2>Table of Contents</h2>
        <ContentsElement contents={tableOfContents} />
    </div>
}

export default TableOfContents
